<template>
    <div>
        <div class="breif-wrapper">
            <div>
                <div class="row">
                    <div class="col">
                        <div class="breif-content" @click="viewShopDetail(shop.id)">
                            <div class="row">
                                <div class="col-auto text-start" style="padding-right: 0px;">
                                    <img class="shop-avatar" :src="getShopAvatar(shop.logo)" alt="shop avatar here"
                                        style="margin-top: 4px;">
                                </div>
                                <div class="col mt-auto" style="margin-left:16px">
                                    <div class="row">
                                        <div class="col" style="padding:0px;">
                                            <div class="store-name no-line-change">{{ shop.name }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" style="padding:0px;">
                                            <div class="address no-line-change">{{ shop.address }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 16px;">
                                <div class="col attribute_tags">
                                    <div class="tag no-line-change" id="discount"><span>{{ shop.influencerDiscount
                                    }}</span>%
                                        off</div>
                                    <div class="tag no-line-change" id="discount-ceiling">Maximum
                                        ${{ shop.discountCeiling ? shop.discountCeiling : '50' }} Off</div>
                                    <div class="tag no-line-change" id="category">{{ shop.category }}</div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    Opens to
                                    <i class="bi bi-star-fill checked"></i>
                                    <i class="bi bi-star-fill checked"></i>
                                    <i class="bi bi-star-fill checked"></i>
                                    <i class="bi bi-star-fill checked"></i>
                                    <i class="bi bi-star-fill"></i>
                                </div>
                            </div>
                            <!-- <div class="row" style="margin-top:16px">
                                <div class="col">
                                    <div class="navigation">
                                        <span style="margin-right: 8px;"><i class="bi bi-eye"></i></span>View Detail
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['shop'],
    name: "ShopBreif",
    data() {
        return {}
    },
    methods: {
        getShopAvatar(logo) {
            return logo || require('@/assets/shop_avatar.svg');
        },
        viewShopDetail(id) {
            this.$router.push({ path: '/shop-detail', query: { id: id } })
        },
    },
    components: {}


}
</script>
<style scoped>
.breif-wrapper {
    padding: 16px;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.store-name {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
}

.shop-avatar {
    border-radius: 4.8px;
    width: 48px;
    height: 48px;
}

.address {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.attribute_tags {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;

}

/*tags and contains id*/
.tag {
    display: inline-block;
    margin-right: 4px;
    padding: 2px 10px;
    border-radius: 29px;
}

#discount-ceiling {
    background-color: #FFEEEB;
    color: #CD0000;
}

#discount {
    background-color: #CD0000;
    color: #FFFFFF;
}

#category {
    background-color: #F2F2F2;
    color: #7E7E7E;
}

/*influencers conditions and navigation to shops*/
.entry-conditions {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #7E7E7E;
    margin-top: 16px;

}
.checked {
    color:orange
}

.navigation {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;

}

.requirements-content {
    margin-top: 8px;
    padding: 0px;
}

::v-deep .requirements-content ol {
    margin-bottom: auto;
    padding: 0;
}

::v-deep .requirements-content li {
    margin-bottom: 8px;
    margin-left: 16px;
}
</style>