export const getMyPosition = () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      };
  
      function success(pos) {
        const crds = pos.coords;
        const currentLocation = {
          lat: parseFloat(crds.latitude),
          lng: parseFloat(crds.longitude)
        };
        resolve(currentLocation);
      }
  
      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        reject(err);
      }
  
      navigator.geolocation.getCurrentPosition(success, error, options);
    });
  };
  