import { db } from "./db"
import { collection, getDocs } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";


export const getAllShops = async () => {
    const querySnapshot = await getDocs(collection(db, "shops"));
    let list = []
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        var shop = doc.data()
        shop.id = doc.id
        shop.position.lat = parseFloat(shop.position.lat)
        shop.position.lng = parseFloat(shop.position.lng)
        list.push(shop)
    });
    const avaliableShops = list.filter(s=>s.connectedAccountId)
    const shops = avaliableShops.filter(s=>s.newMerchant)
    return shops
}

export const getShopById = async(id) => {
    const docRef = doc(db, "shops", id);
    const docSnap =  await getDoc(docRef);

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        let data = docSnap.data()
        data.id = docSnap.id
        return data
    } else {
        // docSnap.data() will be undefined in this case
        // console.log("No such document!");
        return false
    }
}