<template lang="">
    <div>
        <div class="row">
            <div class="col">
                <div class="shop" @click="viewDetail">
                    <div class="row" style="width:100%">
                        <div class="col-auto text-start" style="padding:0px">
                            <img class="shop-avatar" :src="getShopAvatar(shop.logo)" alt="shop avatar here" style="margin-top: 4px;">
                        </div>
                        <div class="col mt-auto" style="margin-left: 16px;">
                            <div class="row">
                                <div class="col" style="padding: 0px;">
                                    <div class="store-name no-line-change">{{shop.name}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="padding: 0px;">
                                    <div class="address no-line-change">{{shop.address}}</div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 12px;">
                                <div class="col attribute_tags">
                                    <div class="tag no-line-change" id="weight-index"><span>X</span>{{shop.weightedIndex}}</div>
                                    <div class="tag no-line-change" id="discount"><span>{{shop.influencerDiscount}}</span>% off</div>
                                    <div class="tag no-line-change" id="category">{{shop.category}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- {{shop}} -->
    </div>
</template>
<script>
export default {
    props: ['shop'],
    data() {
        return {}
    },
    methods: {
        viewDetail() {
            this.$router.push({ path: '/shop-detail', query: { id: this.shop.id } })
        },
        getShopAvatar(logo) {
            return logo || require('@/assets/shop_avatar.svg');
        }
    },
    mounted() {

    }
}
</script>
<style scoped>
.shop {
    padding-top: 24px;
    padding-bottom: 16px;
    margin: 0px 16px 0px 16px;
}

.store-name {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
}

.shop-avatar {
    border-radius: 4.8px;
    width: 48px;
    height: 48px;

}

.address {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.attribute_tags {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    padding: 0px;

}

.tag {
    display: inline-block;
    margin-right: 4px;
    padding: 2px 8px 2px 8px;
    border-radius: 29px;
}

#weight-index {
    background-color: #CD0000;
    color: #FFFFFF;
}

#discount {
    background-color: #FFEEEB;
    color: #CD0000;
}

#category {
    background-color: #F2F2F2;
    color: #7E7E7E;
}
</style>