<template>
    <div class="container">
        <div class="MapHeader">
            <div class="row">
                <div class="col pr-0">
                    <div class="search-bar">
                        <b-input-group>
                            <template #prepend>
                                <b-input-group-text>
                                    <div class="centered-content">
                                        <img src="@/assets/search.svg" alt="Search here" class="centered-vertically" />
                                    </div>
                                </b-input-group-text>
                            </template>
                            <!-- <b-form-input id="search-input" class="shadow-none" style="box-shadow: none;"
                                v-bind="$attrs" :value="value" @input="$emit('input', $event.target.value)"  placeholder="Search anything you want..."></b-form-input> -->
                            <input class="form-control" v-bind="$attrs" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" placeholder="Search anything you want...">
                        </b-input-group>
                    </div>
                </div>
                <div class="col-auto mt-auto">
                    <!-- if not logged in, use default value -->
                    <div v-if="!$store.state.influencer">
                        <div @click="login">
                            <img class="avatar" src="../../assets/avatar.svg" alt="">
                        </div>
                    </div>
                    <div v-if="$store.state.influencer">
                        <div>
                            <img @click="viewProfile" class="avatar" :src="$store.state.influencer.avatar" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="filter-tags" style="margin-top: 20px;">
                        <div v-for="(tag, index) in tags" :key="index" class="tag" :class="{ 'active': tag == selectedTag }"
                            @click="selectTag(tag)">
                            {{ tag }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MapHeader",
    props: ["MapHeader", "value"],
    data() {
        return {
            tags: ['All', 'Food', 'Entertainment', 'Beauty', 'Service', 'Healthy', 'Fashion'],
            selectedTag: "All"
        }
    },
    watch: {

    },
    methods: {
        selectTag(tag) {
            this.selectedTag = tag;
            this.$emit('filterShops', tag)
        },
        login() {
            this.$router.push({ path: "/login" })
        },
        updateKeyword(keyword) {
            console.log("Updaing keyword", keyword)
            this.$emit("newKeyWord", keyword)
        },
        viewProfile() {
            this.$router.push('/profile')
        }
    }

}
</script>
<style scoped>
.MapHeader {
    /* position: fixed; */
    background: white;
    height: auto;
    width: 100%;
    padding-top: 10px;
}

/* search bar: vertical and horizontal centered */
.search-bar {
    width: 100%;
    display: flex;
    align-items: center;
}

.search-bar .form-control {
    background: #F2F2F2;
    border-left: none;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    padding: 0px 16px 0px 16px;
    height: 40px;
}

.search-bar .input-group-prepend .input-group-text {
    display: block !important;
    background: #F2F2F2;
    border-right: none;
    border-top-right-radius: 0;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 0;
    padding: 0px 16px 0px 16px;
    height: 40px;
}

.centered-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.centered-vertically {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.filter-tags {
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    background-color: white;
    margin-right: 8px;
    width: 100%;
    padding-bottom: 17px;

}

.filter-tags::-webkit-scrollbar {
    display: none;
}

.tag {
    padding-top: 4px;
    margin-right: 20px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;

}

.tag.active {
    font-weight: bold;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;

}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.form-control:focus {
    border: 1px solid #ced4da;
    border-left: none;
    outline: none;
    box-shadow: none
}

</style>