<template>
    <div>
        <div style="height: 100vh; width: 100vw; overflow: hidden; max-height: -webkit-fill-available;">
            <!-- header of the page, with search, filter function -->
            <div class="header">
                <MapHeader MapHeader="MapHeader" @filterShops="filterShops" v-model="keyword" />
            </div>
            <!-- end header -->
            <!-- map goes here -->
            <div class="body" style="height: 100%;">
                <GmapMap :center="center" :zoom="zoomLevel" map-type-id="roadmap" :options="mapOptions" style="height: 100%;">
                    <!-- map cluster goes here -->
                    <!-- map markers goes here -->
                    <!-- map cluster ends here -->
                    <!-- map signle marker goes here -->
                    <!-- map user marker goes here -->
                    <GmapMarker :key="index" v-for="(shop, index) in filteredShops" :position="shop.position"
                        :clickable="true" :draggable="false" @click="selectShop(shop)"
                        :icon="shop.connectedAccountId ? '' : lockedShopIcon" />
                    <GmapMarker v-if="!loading" :position="currentLocation" :clickable="true" :draggable="false"
                        :icon="influencerIcon" />
                </GmapMap>
                <!-- search bar result view here -->
            </div>
            <!-- shop breif -->
            <div v-if="selectedShop" id="shop-breif-layer" @click="selectedShop = null">
                <div id="shop-breif-wrapper">
                    <ShopBreif :shop="selectedShop" />
                </div>
            </div>
            <!-- pwa install notification -->
            <PWAPrompt :promptOnVisit="1" :timesToShow="3" copyClosePrompt="Close" :permanentlyHideOnDismiss="false"/>
            <!-- pwa install notification ends here -->
            <!-- shop breif ends here -->
            <!-- map ends here -->
            <div class="footer">
                <ListView :shops="filteredShops" :keyword="keyword" />
            </div>
            <!-- float button rank -->
            <!-- float button rank ends here -->
            <!-- float button view in list -->
            <!-- float button view in list ends here -->
        </div>
    </div>
</template>
<script>

import MapHeader from '@/components/MapView/MapHeader.vue';
import ListView from './ListView.vue';
import { mapOptions } from "@/config/mapOptions"
import { getMyPosition } from "@/utils/map"
import { getAllShops } from "@/utils/shop"
import ShopBreif from '@/components/MapView/ShopBreif.vue';
import { Indicator } from 'mint-ui';
import PWAPrompt from 'vue2-ios-pwa-prompt'
export default {
    name: 'MapView',
    data() {
        return {
            // center will be current location by default, it indicates the center of map by default.
            center: { lat: -37.8099039, lng: 144.9625258 },
            currentLocation: {},
            apiKey: "",
            merchantIcon: "",
            vipMerchantIcon: "",
            shops: [],
            mapOptions: mapOptions,
            influencerIcon: "https://firebasestorage.googleapis.com/v0/b/qrpay-tech.appspot.com/o/images%2Fman.svg?alt=media&token=f5af217a-6bfb-4380-89f0-3680765b7cb6",
            loading: true,
            selectedShop: null,
            category: null,
            keyword: null,
            zoomLevel:15,
            lockedShopIcon: "https://firebasestorage.googleapis.com/v0/b/qrpay-tech.appspot.com/o/images%2Flocation-pin-lock%20(1).svg?alt=media&token=4c13ec20-135f-496c-93e3-d81552631d6c"

        }
    },
    methods: {
        // retrive all shops
        async getAllshops() {
            // this function retreives all the merchant with influradar from firestore, it is used to list render all the markers on the map
            this.shops = await getAllShops()
        },
        getCurrentLocation() {
            // this function retreives current location of user, if user reject geolocation service, it will be located at CBD of mlebourne.

        },
        showBreif() {
            // this function shows breif of the selected merchant, it should be called when a marker is clicked
        },
        showDetails() {
            // this function navigate to details page with selected merchant id, shows details of slected merchant
        },
        showRank() {
            // this function opens ranking page
        },
        showGifts() {
            // this function shows special gifts to influencers
        },
        selectShop(shop) {
            this.center = shop.position
            this.selectedShop = shop
        },
        filterShops(category) {
            if (category == "All") {
                this.category = null
            } else {
                this.category = category
                this.zoomLevel = 10
            }
        }

    },
    computed: {
        filteredShops() {
            const self = this;
            const shops = this.shops;

            if (self.category && self.keyword) {
                const filteredSearchedShops = shops.filter(s =>
                    s.category.replace(/\s+/g, ' ').trim().toLowerCase() === self.category.replace(/\s+/g, ' ').trim().toLowerCase()
                    && Object.values(s).some(value => String(value).toLowerCase().includes(self.keyword.toLowerCase()))
                );
                return filteredSearchedShops;
            }
            else if (self.category) {
                const filteredShops = shops.filter(s =>
                    s.category.replace(/\s+/g, ' ').trim().toLowerCase() === self.category.replace(/\s+/g, ' ').trim().toLowerCase()
                );
                return filteredShops
            } else if (self.keyword) {
                const searchedShops = shops.filter(s =>
                    Object.values(s).some(value => String(value).toLowerCase().includes(self.keyword.toLowerCase()))
                );
                return searchedShops
            }
            else {
                return shops
            }
        },

        sortedMerchant() {
            // return sorted list of shops by either discount or weightedIndex to influencers.
            return false
        }
    },
    async mounted() {
        // retreive functions should be called here
        Indicator.open()
        try {
            this.currentLocation = await getMyPosition()
            this.center = this.currentLocation
        }
        catch(err) {
            console.log(err)
            this.center = { lat: -37.8099039, lng: 144.9625258 }
            this.currentLocation = { lat: -37.8099039, lng: 144.9625258 }
        }
        try {
            this.shops = await getAllShops()
        }
        catch (err) {
            console.log(err)
        }
        this.loading = false
        Indicator.close()

    },
    watch:{
        keyword(newVal) {
            if(newVal) {
                this.zoomLevel = 10
            }
        }
    },
    components: {
        MapHeader,
        ListView,
        ShopBreif,
        PWAPrompt
    }
}
</script>
<style scoped>
.body {
    z-index: 0;
}

.footer {
    z-index: 1;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

#shop-breif-wrapper {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    bottom: 20vh;
    z-index: 3;
    width: 80vw;
}

#shop-breif-layer {
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    z-index: 6;
}
</style>