import Vue from 'vue'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import { getInfluecerByPhone } from '@/utils/influencer'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    currentUser: null,
    influencerPhone:null,
    influencer:null
  },
  getters: {
  },
  mutations: {
    setCurrentUser(state) {
      const storedUser = JSON.parse(localStorage.getItem("currentUser"))
      state.currentUser = storedUser
      state.influencerPhone = storedUser.phoneNumber.replace("+61","0")
    },
    async setInfluencer(state) {
      const influencer = await getInfluecerByPhone(state.influencerPhone)
      state.influencer = influencer
    },
    removeUserInfo(state) {
      state.currentUser = null
      state.influencerPhone = null
      state.influencer = null
    }
  },
  actions: {
  },
  modules: {
  }
})
