export const mapOptions = {
    styles: [{
        elementType: "geometry",
        stylers: [{ color: "f1f1f1" }],
    },
    {
        featureType: 'landscape',
        elementType: "labels",
        stylers: [
            { visibility: "on" },
        ]
    },
    {
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
    },
    {
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text",
        stylers: [{ visibility: 'off' }, { color: '757575' }],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "geometry",
        stylers: [{ visibility: 'on' }, { color: 'ffffff' }],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "f1f1f1" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#ffffff" }],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#f1f1f1" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }, { visibility: 'off' }],
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }, { visibility: 'on' }],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }, { visibility: 'on' }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#e3e3e3" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    ],
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    maxZoom: 25,
    URL: ''
    // direction options below

}