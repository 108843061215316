import Vue from 'vue'
import VueRouter from 'vue-router'
import MapView from '../views/MapView.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'MapView',
        component: MapView
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/playground',
        name: 'PlaygroundName',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "PlaygroundName" */ '../views/PlaygroundView.vue')
    },
    // All the view pages here
    {
        path: '/ranking',
        name: 'RankingView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "MapView" */ '../views/RankingView.vue')
    },
    {
        path: '/login',
        name: 'LoginView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "LoginView" */ '../views/LoginView.vue')
    },
    {
        path: '/shop-detail',
        name: 'ShopDetailView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "ShopDetailView" */ '../views/ShopDetailView.vue')
    },
    {
        path: '/profile',
        name: 'ProfileView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "ProfileView" */ '../views/ProfileView.vue')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router