<template>
    <div>
        <div class="list-layer" v-hammer:swipe.vertical="(event) => handleSwipe(event)"
            :style="{ 'height': layerHeight + 'vh' }">
            <div style="min-height: 9vh;">
                <div class="d-flex">
                    <div id="handler" class="mx-auto">
                    </div>
                </div>
                <div class="list-view">
                    <div class="list-title">
                        List view
                    </div>
                </div>
            </div>
            <div v-if="keyword" class="list-store-numbers">
                {{ shops.length }} stores in your area
            </div>
            <div style="overflow-y: scroll; height: 100%; overflow-x: hidden; padding-bottom: 100px;">
                <ListViewItem v-for="shop in shops" :key="shop.id" :shop="shop" />
            </div>
        </div>
    </div>
</template>
<script>
import ListViewItem from "@/components/ListView/ListViewItem"
export default {
    props: ['shops', 'keyword'],
    data() {
        return {
            layerHeight: 9
        }
    },
    methods: {
        handleSwipe(e) {
            if (e) {
                if (e.type == "swipeup") {
                    this.layerHeight = 80
                }
                if (e.type == "swipedown") {
                    this.layerHeight = 9
                }
            }
        }
    },
    components: {
        ListViewItem
    },
    mounted() {

    }
}
</script>
<style scoped>
.list-layer {
    width: 100%;
    height: auto;
    padding: 8px 24px 24px 24px;
    border-radius: 20px 20px 0px 0px;
    background: var(--light-background-primary, #FFF);
    /* 11 */
    box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.16);
    max-height: 80vh;
    transition: height 1s;
}

.list-title {
    color: #2A3443;
    font-family: VIC;
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;

}

.list-store-numbers {
    font-family: VIC;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

#handler {
    background-color: #D9D9D9;
    width: 60px;
    height: 4px;
    margin-bottom: 20px;
}
</style>