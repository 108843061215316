import { db } from "./db"

import { doc, getDoc } from "firebase/firestore";

export const getInfluecerByPhone = async (id) => {
    const docRef = doc(db, "influencers", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let data = docSnap.data()
        data.id = docSnap.id
        return data
    } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        return false
    }
}