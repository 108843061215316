import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import { VueHammer } from 'vue2-hammer'
import { initializeApp } from 'firebase/app'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.use(MintUI)
Vue.use(VueHammer)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyClZ4fp-i7s6W5wLiaVNHNwQSJ8ZPFaru4',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})
Vue.config.productionTip = false
if (localStorage.getItem("currentUser")) {
  store.commit("setCurrentUser")
  store.commit("setInfluencer", )
}

export const firebaseApp = initializeApp({
  // your application settings
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
